import { Motion } from "@repo/solid-motionone";
import { useNavigate, A } from "@solidjs/router";
import { TbArrowRight, TbRosette } from "solid-icons/tb";
import { type Component, For } from "solid-js";
import { MarketingCTA } from "~/components/cta/MarketingCTA";
import { StIcon } from "~/components/icons";
import type { CampaingPageDataLevel, NormalizedCampaignLevel, CampaignLevel } from "../useUseCasesData";
import { fadeIn, HomePageIconsMapper } from "./HomeScreen";

export const UseCasesRootHomePage: Component<{
  data: CampaingPageDataLevel;
}> = (props) => {
  const navigate = useNavigate();

  return (
    <div class="px-10 mx-auto pb-4">
      <div class="flex flex-col md:flex-row gap-4">
        <For each={props.data.level.children as NormalizedCampaignLevel[]}>
          {(item) => (
            // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
            <div
              onClick={() => navigate(`/${props.data.breadcrumbs.join("/")}/${item.id}`.replace("//", "/"))}
              class="cursor-pointer group px-6 py-4 text-white rounded-lg bg-gray-400/5 border border-transparent mb-2 hover:border hover:border-purple-700 transition"
            >
              <div class="mb-6 flex items-center justify-between flex-wrap gap-2">
                <MarketingCTA compact icon={false}>
                  {item.name}
                </MarketingCTA>

                <A
                  href={`/${props.data.breadcrumbs.join("/")}/${item.id}`.replace("//", "/")}
                  onClick={(e) => e.stopImmediatePropagation()}
                  class="flex justify-end items-center text-white group-hover:text-violet-200 text-base leading-normal group-hover:underline group-hover:underline-offset-2"
                >
                  See all use cases
                  <StIcon class="mt-1" icon={TbArrowRight} />
                </A>
              </div>

              <div class="flex flex-wrap gap-2 pl-1 pb-2 mb-6">
                <For each={(item.children as CampaignLevel[]).slice(0, 3)}>
                  {(child, index) => (
                    <Motion.span {...fadeIn(0.1 * index())}>
                      <A
                        href={`/${props.data.breadcrumbs.join("/")}/${item.id}/${child.id}`.replace("//", "/")}
                        onClick={(e) => e.stopImmediatePropagation()}
                        class="group/item rounded-full bg-gray-400/5 flex px-2 items-center gap-2 text-base hover:underline hover:underline-offset-2 capitalize"
                      >
                        <StIcon class="text-indigo-400" icon={HomePageIconsMapper[child.id] || TbRosette} />
                        {child.name}
                      </A>
                    </Motion.span>
                  )}
                </For>
              </div>
            </div>
          )}
        </For>
      </div>
    </div>
  );
};
